[data-key='num-sp'] {
  display: none;
}

.sd-container-modern .sv-dropdown_select-wrapper input[type=text] {
  outline: none !important;
  border: none;
  box-shadow: none;
}

.border-kill {
  border: none !important;
}

.spg-search-editor_input {
  box-sizing: initial !important;
}

.spg-search-editor_container {
  height: auto;
  @apply my-auto w-full h-14;
}

.spg-search-editor_container {
  height: 56px !important;
  width: 100% !important;
}

.spg-search-editor_container input {
  @apply w-full;
  background-color: var(--enhancetitle);
  margin: 10px;
}

.sd-table__cell--actions:not(.sd-table__cell--vertical):last-child .sd-action-bar {
  background: transparent !important;
}

.markdown-text a {
  @apply text-ev-blue;
  @apply underline
}

.svc-creator__non-commercial-text {
  display: none !important;
}

.svc-creator {
  --sjs-primary-backcolor: var(--enhancetitle);
  --sjs-secondary-backcolor-semi-light: var(--enhance);
  --sjs-general-backcolor: white;
  --border: #888;
  /*--sjs-base-unit: 12px;*/
}
