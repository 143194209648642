:global(:root) {
  --layout-grid-header-height: 32px;
  --layout-grid-margin: 32px;
  --ag-accent-color: hsl(var(--enhancetitle));
  --ag-row-hover-color: hsl(var(--enhancetitle));
  --ag-header-background-color: transparent !important;
}

:global(.ag-theme-quartz .ag-root-wrapper),
:global(.ag-theme-quartz-dark .ag-root-wrapper) {
  border: none;
}

:global(.ag-center-cols-viewport) {
  min-height: unset !important;
}

:global(.ag-theme-quartz .ag-row), :global(.ag-theme-quartz-dark .ag-row) {
  border-bottom: none;
}

:global(.ag-theme-quartz .ag-row:not(.ag-row-level-1)::before),
:global(.ag-theme-quartz-dark .ag-row:not(.ag-row-level-1)::before) {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  transition: background-color 0.2s ease-in-out;
}
